
export default {
  validateSchemeName(name){
    if(name.length<4 || name.length>150){
      return 1;
    }else if(!(new RegExp('^([a-zA-Z0-9\\s])+$','i')).test(name)){
      return 2;
    }
    return 0;
  },
  removeSchemeItem_(item){
    var index = this.edit_data.items_product.indexOf(item);
    if (index !== -1) {
      this.edit_data.items_product.splice(index, 1);
    }
  },
  addNewScheme_(){
    var item = {
      id: 0,
      product_id: 0,
      w1: '',
      w2: '',
      w3: '',
      w4: '',
      w5: '',
      w6: '',
      w7: '',
      w8: '',
      w9: '',
      w10: '',
      w11: '',
      w12: ''
    }
    this.edit_data.items_product.push(item);
  },
  validateSchemeProducts(items_product){
    if(items_product.length < 1){
      return 1;
    }else {
      for(let val of items_product) {
        if(!val.product_id){
          return 2;
        }
        for(let w = 1; w<=12; w++){
          if(!val['w'+w] || val['w'+w] == '') val['w'+w] = 0;//console.log(w+':'+val['w'+w])
          if(!/^(?!0\d)\d*(\.\d{1,2})?$/.test(val['w'+w])){
            return 3;
          }else if(parseFloat(val['w'+w])<0 || parseFloat(val['w'+w]) > 100){
            return 4;
          }
        }
      }
    }
    return 0;
  },
}